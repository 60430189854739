<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <div class="header">服務商資訊</div>
        <div class="search-area">
            <div class="flex:1">
                <el-input
                    v-model="input.name"
                    placeholder="搜尋服務商暱稱"
                    prefix-icon="el-icon-search"
                    size="small"
                    style="margin-right: 15px"
                    @change="filterData"></el-input>
            </div>
            <div class="flex:1">
                <el-select v-model="input.roles" placeholder="上架狀態" size="small" style="margin-right: 15px" @change="filterData">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
            </div>
        </div>
        <div class="container">
            <div v-for="item in list" :key="item.id" class="card">
                <div class="card-top">
                    <div class="left-item">
                        <img class="image" :src="item.avatar" alt="" />
                        <div class="name">{{ item.name }}</div>
                    </div>
                    <div class="status">
                        <div v-if="item.role == 1" class="item active">已上架</div>
                        <div v-else class="item no-active">未上架</div>
                    </div>
                </div>
                <div class="card-mid">
                    <div class="card-mid-left">
                        <div class="item">
                            本月營收：<span> {{ item.expected_revenue }}</span>
                        </div>
                    </div>
                    <div class="card-mid-right">
                        <div class="item">
                            本月訂單數：<span>{{ item.dating_count }} 筆</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="list.length === 0" class="not-find">未有符合資料</div>
        </div>
    </div>
</template>

<script>
import LoadingComponent from "@/components/Loading.vue";

export default {
    name: "ProviderList",
    components: {
        LoadingComponent,
    },
    computed: {
        inputData() {
            const request = {};
            if (this.input.roles) {
                request["roles"] = [this.input.roles];
            }
            if (this.input.name) {
                request["name"] = this.input.name;
            }
            return request;
        },
    },
    data() {
        return {
            loading: true,
            options: [
                {
                    label: "全部",
                    value: "",
                },
                {
                    label: "已上架",
                    value: 1,
                },
                {
                    label: "未上架",
                    value: 2,
                },
            ],
            input: {
                roles: "",
                name: "",
            },
            list: [],
        };
    },
    methods: {
        filterData() {
            this.loading = true;
            this.$api
                .SearchMyProvider(this.inputData)
                .then((res) => {
                    this.list = [...res.data.providers.filter((i) => i.status >= 0)];
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.filterData();
    },
};
</script>

<style lang="scss" scoped>
.header {
    width: 100%;
    height: 50px;
    display: flex;
    background: #fff;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
}
.search-area {
    width: 100%;
    height: 50px;
    display: flex;
    background: #fff;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 50px;
    left: 0;
    padding: 0 20px;
    gap: 15px;
}
.not-find {
    width: 100%;
    text-align: center;
    color: rgb(70, 65, 65);
}
.container {
    width: 100%;
    background: #fafafa;
    max-width: 600px;
    min-height: 100vh;
    padding: 120px 15px 70px;
    .card {
        background: #fff;
        border: #ccc 1px solid;
        border-radius: 10px;
        width: 100%;
        padding: 15px;
        margin-bottom: 20px;
        .card-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left-item {
                display: flex;
                align-items: center;
            }
            .image {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
            .name {
                font-weight: 500;
                margin-left: 15px;
                justify-self: start;
            }
            .status {
                display: flex;
                .item {
                    padding: 1px 10px;
                    border: 2px solid red;
                    border-radius: 5px;
                    text-align: center;
                    margin: 5px;
                    font-size: 14px;
                    &.arrive {
                        border: 2px solid #23b400;
                        color: #23b400;
                    }
                    &.no-arrive {
                        border: 2px solid #ff0000;
                        color: #ff0000;
                    }
                    &.no-active {
                        border: 2px solid #757575;
                        color: #757575;
                    }
                    &.active {
                        border: 2px solid #ff5733;
                        color: #ff5733;
                    }
                }
            }
        }
        .card-mid {
            display: flex;
            padding: 10px 4px;
            .card-mid-left {
                flex: 1;
                height: 100%;
                border-right: 1px solid #ccc;
                padding-right: 10px;
            }
            .card-mid-right {
                height: 100%;
                flex: 1;
                padding-left: 15px;
            }
            .item {
                color: gray;
                font-size: 14px;
                padding: 5px 0;
                span {
                    color: #000;
                    margin-left: 2px;
                    font-size: 12px;
                }
            }
        }
        .card-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid #ccc;
            color: #757575;
            padding: 15px 0;
            font-size: 14px;
            padding: 15px 15px 0;
            .val {
                color: #ff5733;
                font-weight: 700;
                font-size: 18px;
            }
        }
    }
}
</style>
